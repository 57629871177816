module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-redux-persist@1.1.0_react-redux@9.1.2_react@18.3.1_redux-persist@6.0.0/node_modules/gatsby-plugin-react-redux-persist/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore","serialize":{"space":0,"isJSON":true,"unsafe":false,"ignoreFunction":true},"cleanupOnClient":true,"windowKey":"__PRELOADED_STATE__"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Auroville Today","short_name":"Auroville Today","start_url":"/","background_color":"#000","theme_color":"#000","display":"standalone","icon":"./src/images/logos/favicon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a40c42ff157e342c5a353d95d176d6c"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@5.13.1_gatsby@5.13.7/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.1.6/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
