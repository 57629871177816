// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import * as actions from './actions'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Reducers
// ----------------------------------------------------------------------------
// const dayColor = `c-${new Date().getDate()}`
const dayColor = `c-0`

/**
    * rootReducer - Root reducer for this package.
    * Root reducer is not called directly, but is comubined and used by Redux to initialise and manage state.
    *
    * @example
      dispatch(someAction({...somePayload...}));
    *
    * @param {object} previousState - A simple object that contains previous state. Default value is an empty object.
    * @param {object} action - An action object of the nature { type: ACTION_KEYWORD, payload: payloadObject }
    * @version 0.0.1
    * @todo none
    */
const rootReducer = (
  previousState = {
    counter: 0,
    gridOverlayView: false,
    gridOverlay: 'six-col',
    dayNightMode: 'day',
    stackMode: false,
    color: dayColor,
    type: 't-0',
    colorRandomSwitch: false,
    drawer: false,
    drawerTitle: 'Loading...',
    drawerAbstract: 'Loading...',
    headerState: false,
    headerMenuState: false,
    mediaState: {
      isExtraLargeDesktop: false,
      isLargeDesktop: true,
      isDesktop: false,
      isTablet: false,
      isMobile: false,
      isPortrait: false,
      isRetina: false,
    },
    screenSizeState: {
      screenWidth: 1440,
      screenHeight: 900,
    },
    drawerOpen: false,
    cursor: {
      start: 0,
      end: 0,
      length: 0,
    },
    freeArticleState: {
      numberOfFreeArticlesRemaining: 1,
      lastUsed: null,
    },
    userSystemState: false,
    theme: 't-0',
    size: 'size-3',
    dataBeforeNavigation: {},
  },
  action
) => {
  switch (action.type) {
    case actions.INCREASE:
      return Object.assign({}, previousState, {
        counter: action.payload,
      })
    case actions.DECREASE:
      return Object.assign({}, previousState, {
        counter: action.payload,
      })
    case actions.UPDATE_MEDIA_STATE:
      return Object.assign({}, previousState, {
        mediaState: action.payload,
      })
    case actions.UPDATE_SCREEN_SIZE_STATE:
      return Object.assign({}, previousState, {
        screenSizeState: action.payload,
      })
    case actions.UPDATE_HEADER_STATE:
      return Object.assign({}, previousState, {
        headerState: action.payload,
      })
    case actions.UPDATE_GRID_OVERLAY_VIEW_STATE:
      return Object.assign({}, previousState, {
        gridOverlayView: action.payload,
      })
    case actions.UPDATE_GRID_OVERLAY_STATE:
      return Object.assign({}, previousState, {
        gridOverlay: action.payload,
      })
    case actions.UPDATE_DAY_NIGHT_MODE:
      return Object.assign({}, previousState, {
        dayNightMode: action.payload,
      })
    case actions.UPDATE_COLOR:
      return Object.assign({}, previousState, {
        color: action.payload,
      })
    case actions.UPDATE_COLOR_RANDOM_SWITCH:
      return Object.assign({}, previousState, {
        colorRandomSwitch: action.payload,
      })
    case actions.UPDATE_DRAWER:
      return Object.assign({}, previousState, {
        drawer: action.payload,
      })
    case actions.UPDATE_DRAWER_TITLE:
      return Object.assign({}, previousState, {
        drawerTitle: action.payload,
      })
    case actions.UPDATE_DRAWER_ABSTRACT:
      return Object.assign({}, previousState, {
        drawerAbstract: action.payload,
      })
    case actions.UPDATE_TYPE:
      return Object.assign({}, previousState, {
        type: action.payload,
      })
    case actions.TOGGLE_HEADER_MENU:
      return Object.assign({}, previousState, {
        headerMenuState: action.payload,
      })
    case actions.UPDATE_DRAWER_STATE:
      return Object.assign({}, previousState, {
        drawerOpen: action.payload,
      })
    case actions.UPDATE_CURSOR:
      return Object.assign({}, previousState, {
        cursor: action.payload,
      })
    case actions.UPDATE_USER_SYSTEM_STATE:
      return Object.assign({}, previousState, {
        userSystemState: action.payload,
      })
    case actions.UPDATE_FREE_ARTICLE_STATE:
      return Object.assign({}, previousState, {
        freeArticleState: action.payload,
      })
    case actions.UPDATE_STACK_MODE:
      return Object.assign({}, previousState, {
        stackMode: action.payload,
      })
    case actions.UPDATE_THEME:
      return Object.assign({}, previousState, {
        theme: action.payload,
      })
    case actions.UPDATE_SIZE:
      return Object.assign({}, previousState, {
        size: action.payload,
      })
    case actions.SET_DATA_BEFORE_NAVIGATION:
      return Object.assign({}, previousState, {
        dataBeforeNavigation: action.payload,
      })
    case actions.RESET_DATA_BEFORE_NAVIGATION:
      return Object.assign({}, previousState, {
        dataBeforeNavigation: {},
      })
    default:
      return previousState
  }
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default rootReducer
